/* There are two pages associated with payment, checkout and payment. */

.checkout {
    & .grid-container {
        flex-grow: 1;
    }
}

.payment {
    & .grid-container {
        flex-grow: 1;
    }

    & .loading-container,
    & .api-error {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
