.static-content {
    & :--heading {
        /* Enough space to account for the header */
        scroll-margin-top: 100px;
    }

    & .content-section {
        --card-margin-bottom: 0;
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;
    }

    & .page-title {
        grid-column: 1 / -1;

        & h1 {
            --heading-margin: 32px 0 0 0;

            & + h3 {
                --heading-margin: 32px 0 0 0;
            }
        }
    }

    & .content-item {
        grid-column: 1 / -1;
        margin-bottom: 64px;

        & :last-child {
            margin-bottom: 0;
        }
    }

    & .shade-light-grey {
        background: var(--denim-color-shade-light-grey);
    }

    & .table-wrapper {
        grid-column: 1 / -1;
        margin-bottom: 32px;

        & table {
            border: none;
            border-collapse: separate;
            border-spacing: 0;
            table-layout: fixed;
            width: 100%;
        }

        & td {
            display: block;
            padding: 16px 16px 16px 0;
            vertical-align: top;

            & h1:first-child,
            & h2:first-child,
            & h3:first-child,
            & h4:first-child,
            & h5:first-child,
            & p:first-child {
                margin-top: 0;
            }
        }

        & .has-borders {
            & td {
                border: 2px solid var(--denim-color-shade-light-grey);
                border-top: none;
                padding: 16px;
            }

            & tr:first-child td:first-child {
                border-top: 2px solid var(--denim-color-shade-light-grey);
                border-top-left-radius: var(--denim-border-radius, 8px);
                border-top-right-radius: var(--denim-border-radius, 8px);
            }

            & tr:last-child td:last-child {
                border-bottom-left-radius: var(--denim-border-radius, 8px);
                border-bottom-right-radius: var(--denim-border-radius, 8px);
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & .content-item {
            grid-column: span 6;
        }

        .table-wrapper {
            & td {
                display: table-cell;
            }

            & .has-borders {
                & td:not(:first-child) {
                    border-left: none;
                }

                & tr:first-child td {
                    border-top: 2px solid var(--denim-color-shade-light-grey);
                }

                & tr:first-child td:first-child {
                    border-top-left-radius: var(--denim-border-radius, 8px);
                    border-top-right-radius: 0;
                }

                & tr:first-child td:last-child {
                    border-top-right-radius: var(--denim-border-radius, 8px);
                }

                & tr:last-child td:last-child {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: var(--denim-border-radius, 8px);
                }

                & tr:last-child td:first-child {
                    border-bottom-left-radius: var(--denim-border-radius, 8px);
                }
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .content-item {
            grid-column: span 8;
        }
    }
}
