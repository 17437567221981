.link-with-attribution {
    --link-with-attribution-attribution-color: var(--denim-color-shade-white-alpha-60);
    --link-with-attribution-margin-top: var(--denim-spacing-16);

    margin-top: var(--link-with-attribution-margin-top);

    & > .attribution {
        color: var(--link-with-attribution-attribution-color);
        margin-top: 4px;
    }

    &.alternate {
        & > .link {
            --link-color: var(--genomics-color-light-green);
        }

        & > .attribution {
            --link-with-attribution-attribution-color: var(--denim-color-shade-white);
        }
    }

    .variant-light & {
        --link-with-attribution-attribution-color: inherit;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --link-with-attribution-margin-top: var(--denim-spacing-24);
        }
    }
}
