.testimonial {
    --testimonial-background: var(--denim-testimonial-background, linear-gradient(55.54deg, var(--genomics-color-purple, rgb(124 37 223)) -67.53%, var(--genomics-color-bright-blue, rgb(13 94 255)) 130.52%));
    --testimonial-border-radius: var(--denim-border-radius, 8px);
    --testimonial-text-color: var(--denim-testimonial-color, var(--denim-color-shade-white, rgb(255 255 255)));
    --testimonial-attribution-color: var(--denim-color-shade-white-alpha-80, rgb(255 255 255 / 80%));
    --testimonial-margin: var(--denim-testimonial-margin, 0);
    --testimonial-padding: var(--denim-testimonial-padding, 24px 16px);

    background: var(--testimonial-background);
    border-radius: var(--testimonial-border-radius);
    color: var(--testimonial-text-color);
    margin: var(--testimonial-margin);
    padding: var(--testimonial-padding);

    & p {
        --paragraph-bottom-margin: 0;
        --paragraph-top-margin: 16px;

        color: var(--testimonial-attribution-color);
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --testimonial-padding: var(--denim-testimonial-padding-tablet-landscape, 32px 24px);
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --testimonial-padding: var(--denim-testimonial-padding-desktop, 60px 56px);

            & p {
                --paragraph-top-margin: 24px;
            }
        }
    }
}
