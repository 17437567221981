.flex-column-to-row {
    --align-items: flex-start;
    --flex-direction: column;
    --justify-content: center;
    --margin-top: var(--font-size);

    align-items: var(--align-items);
    display: flex;
    flex-flow: var(--flex-direction) nowrap;
    justify-content: var(--justify-content);

    &.margin-on-mobile {
        & :not(:first-child) {
            margin-top: var(--margin-top);
        }
    }

    &.align-items-center {
        --align-items: center;
    }

    &.space-between {
        --align-items: space-between;
    }

    &.space-evenly {
        --align-items: space-evenly;
    }

    @media (--denim-breakpoint-mobile) {
        & {
            &.reversed {
                --flex-direction: column-reverse;
            }
        }
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            &.above-mobile {
                --align-items: flex-start;
                --flex-direction: row;
                --justify-content: stretch;
                --margin-top: 0;

                &.margin-on-desktop {
                    --margin-left: var(--font-size);

                    & :not(:first-child) {
                        margin-left: var(--margin-left);
                    }
                }

                &.space-between {
                    --align-items: center;
                    --justify-content: space-between;
                }

                &.space-evenly {
                    --align-items: center;
                    --justify-content: space-evenly;
                }
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --align-items: flex-start;
            --flex-direction: row;
            --justify-content: stretch;
            --margin-top: 0;

            &.margin-on-desktop {
                --margin-left: var(--font-size);

                & :not(:first-child) {
                    margin-left: var(--margin-left);
                }
            }

            &.space-between {
                --align-items: center;
                --justify-content: space-between;
            }

            &.space-evenly {
                --align-items: center;
                --justify-content: space-evenly;
            }
        }
    }
}

.flex-column {
    --align-items: flex-start;
    --flex-direction: column;
    --justify-content: center;
    --margin-top: var(--font-size);

    align-items: var(--align-items);
    display: flex;
    flex-flow: var(--flex-direction) nowrap;
    justify-content: var(--justify-content);

    &.full-height {
        --align-items: stretch;

        min-height: 100vh;
    }

    &.error-card-container {
        margin-left: auto;
        margin-right: auto;
        width: 66vw;
    }

    & .flex-grow {
        flex-grow: 1;

        &.larger {
            flex-grow: 2;
        }
    }

    & .flex-shrink {
        flex-shrink: 1;

        &.smaller {
            flex-shrink: 2;
        }
    }

    @media (--denim-breakpoint-mobile) {
        & {
            &.reversed {
                --flex-direction: column-reverse;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            &.reversed {
                --flex-direction: column-reverse;
            }
        }
    }
}

.button:disabled {
    cursor: not-allowed;
}

.inline-form-groups {
    display: flex;
    gap: var(--denim-spacing-16);
    margin-top: var(--denim-spacing-32);

    & .form-group {
        align-self: flex-start;
        flex-grow: 1;
        margin-top: 0;
    }
}
