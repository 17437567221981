.performance-graph {
    & .title {
        --title-us-gradient: linear-gradient(90deg, var(--genomics-color-bright-blue) -79.53%, var(--genomics-color-light-green) 99.89%);

        align-items: center;
        display: flex;
        margin: 0 0 var(--denim-spacing-24);

        & > .us {
            --logotype-height: 22px;
            --logotype-width: 157px;

            background: var(--title-us-gradient);
            height: var(--logotype-height);
            margin: 0 var(--denim-spacing-4) 0 0;
            mask: url("/images/health-insights-logotype.svg");
            mask-size: var(--logotype-width);
            width: var(--logotype-width);
        }

        & > .versus {
            color: var(--denim-color-shade-white-alpha-60);
            margin: var(--denim-spacing-2) 0 0;
        }

        & > .them {
            margin: var(--denim-spacing-2) 0 0 var(--denim-spacing-4);
        }
    }

    & .trait {
        --trait-them-gradient: linear-gradient(270deg, var(--denim-color-shade-white-alpha-60) 0.16%, var(--denim-color-shade-white-alpha-20) 100%);
        --trait-us-gradient: linear-gradient(90deg, var(--genomics-color-bright-blue-alpha-40) 0%, var(--genomics-color-light-green) 100%);

        margin: 0 0 var(--denim-spacing-24);

        & > .name {
            color: var(--denim-color-shade-white-alpha-80);
            margin: 0 0 var(--denim-spacing-8);
        }

        & > .datum {
            border-bottom-right-radius: var(--denim-spacing-2);
            border-top-right-radius: var(--denim-spacing-2);
            height: var(--denim-spacing-4);
        }

        & > .us {
            background: var(--trait-us-gradient);
            margin: 0 0 var(--denim-spacing-8);
        }

        & > .them {
            background: var(--trait-them-gradient);
        }
    }

    & .rule-with-arrow {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        & > .rule {
            border-top: 1px solid var(--denim-color-shade-white-alpha-60);
            flex-grow: 1;
        }

        & > .arrow {
            --arrow-height: 3px;
            --arrow-width: 5px;

            border-bottom: var(--arrow-height) solid transparent;
            border-left: var(--arrow-width) solid var(--denim-color-shade-white-alpha-60);
            border-top: var(--arrow-height) solid transparent;
            flex-shrink: 1;
            height: 0;
            width: 0;
        }
    }

    & .scale {
        color: var(--denim-color-shade-white-alpha-60);
        display: flex;
        justify-content: space-between;
        padding: var(--denim-spacing-2) 0 0;
    }

    & .caption {
        color: var(--denim-color-shade-white-alpha-80);
        margin: var(--denim-spacing-8) 0 0;
    }

    & + & {
        margin: var(--denim-spacing-56) 0 0;
    }
}
