.useful-links {
    --useful-links-align-items: flex-start;
    --useful-links-justify-content: center;
    --useful-links-margin: var(--denim-useful-links-margin, 16px 0 0);
    --useful-links-link-gap: var(--denim-useful-links-link-gap, 8px);
    --useful-links-separator-color: var(--denim-useful-links-separator-color, var(--denim-color-shade-mid-grey, rgb(189 189 189)));

    align-items: var(--useful-links-align-items);
    display: flex;
    flex-flow: column;
    justify-content: var(--useful-links-justify-content);
    margin: var(--useful-links-margin);

    & .title {
        --paragraph-bottom-margin: 16px;
        --paragraph-top-margin: 0;
    }

    & a:not(:first-of-type) {
        margin-top: var(--useful-links-link-gap);
    }

    & .useful-links-separator {
        color: var(--useful-links-separator-color);
        display: none;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --useful-links-align-items: center;
            --useful-links-justify-content: flex-start;

            flex-flow: row wrap;

            & .title {
                --paragraph-bottom-margin: 8px;

                width: 100%;
            }

            & a:not(:first-of-type) {
                margin-top: 0;
            }

            & .useful-links-separator {
                display: block;
                margin: 0 var(--useful-links-link-gap);
            }
        }
    }
}
