.home {
    padding: var(--denim-spacing-32) 0 var(--denim-spacing-32);

    & .error-card {
        --error-card-margin: 0;
    }

    & header {
        margin-bottom: var(--denim-spacing-32);

        & .name {
            color: var(--denim-color-shade-white-alpha-60);
            margin-bottom: var(--denim-spacing-8);
        }
    }

    & img {
        --img-aspect-ratio: 343 / 277;
        --img-object-fit: cover;
        --img-object-position: 50% 50%;
        --img-width: 100%;

        aspect-ratio: var(--img-aspect-ratio);
        border-radius: var(--denim-spacing-8);
        max-width: 632px;
        object-fit: var(--img-object-fit);
        object-position: var(--img-object-position);
        width: var(--img-width);
    }

    &.results {
        padding: 0;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            padding: var(--denim-spacing-32) 0 var(--denim-spacing-64);

            &.results {
                padding: 0;
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & img {
                --img-aspect-ratio: 520 / 430;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            & .error-card {
                grid-column: 2 / 10;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .error-card {
                grid-column: 3 / 11;
            }
        }
    }
}
