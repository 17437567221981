.activate,
.test-reorder {
    padding: var(--denim-spacing-24) 0;

    & .layout {
        grid-row-gap: var(--denim-spacing-32);
        grid-template:
            "image ." auto
            "content content" auto
            / 1fr 1fr;
    }

    /* This is the same as the style from the linear-flow hero-image selector */
    & img {
        aspect-ratio: 1040 / 786;
        border-radius: var(--denim-border-radius);
        grid-area: image;
        margin: 0;
        max-width: 632px;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
    }

    & .content {
        grid-area: content;
    }

    & .name {
        color: var(--denim-color-shade-white-alpha-60);
        margin: 0 0 var(--denim-spacing-8);
    }

    & :--heading {
        --heading-margin: 0;
    }

    & .button {
        /* Takes into account hte extra 8px of top padding on the MoreInfo component */
        --button-margin: var(--denim-spacing-32) 0 var(--denim-spacing-24);
        --button-min-width: 100%;

        justify-content: center;

        &.icon {
            /* ::TODO:: Move to the Denim component library CSS */
            --icon-background-color: var(--denim-icon-color-on-light, var(--denim-background-color, var(--genomics-color-navy, rgb(17 0 53))));
        }
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & .button {
                --button-min-width: 152px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .layout {
                grid-template:
                    "image image . . . ." auto
                    "content content content content content content" auto
                    / 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .layout {
                grid-template:
                    "content content content content content content image image image image image image" auto
                    / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }
}

.kit-activation {
    --card-margin: 0 var(--denim-grid-mobile-column-gap, 16px);
    --card-padding: 0;

    & .logout-link {
        --link-color: var(--genomics-color-light-green);
    }

    & .instruction {
        --instruction-margin-bottom: var(--denim-spacing-16);
        --instruction-image-margin: var(--denim-spacing-16);
        --instruction-image-width: 104px;

        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin-bottom: var(--instruction-margin-bottom);

        & > .image {
            flex-shrink: 0;
            margin-right: var(--instruction-image-margin);
            width: var(--instruction-image-width);
        }

        &.more-margin {
            --instruction-margin-bottom: var(--denim-spacing-32);
        }
    }

    &.kit-activation-enter-the-code {
        & .text-input {
            --text-input-width: 50%;
        }
    }

    &.kit-activation-sample-collected {
        & .form-group {
            &.half-margin {
                margin-bottom: var(--denim-spacing-16);
            }

            &:not(:first-of-type) {
                --form-group-controls-margin: 0 0 var(--denim-spacing-8) 0;

                /* RadioButton description left margin is calculated as:
                       margin-left: calc(var(--radio-dimension) + 1px + var(--radio-label-margin));
                   where: --radio-dimension is 24px and --radio-label-margin is 8px */
                margin: 0 0 var(--denim-spacing-32) 33px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            --card-margin: var(--denim-grid-tablet-portrait-margin, 0 var(--denim-spacing-32));
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --card-margin: var(--denim-grid-tablet-landscape-margin, 0 var(--denim-spacing-32));

            & .instruction {
                --instruction-image-margin: var(--denim-spacing-32);
                --instruction-image-width: 166px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --card-margin: var(--denim-grid-desktop-margin, 0 auto);

            & .instruction {
                --instruction-image-margin: var(--denim-spacing-32);
                --instruction-image-width: 152px;
            }
        }
    }
}
