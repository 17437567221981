.supplemental-box {
    /* This is 240 rather than 239 to get round an AXE color-contrast violation */
    --supplemental-box-background-color: rgb(240 240 240);
    --supplemental-box-padding: var(--denim-spacing-24) var(--denim-spacing-16);

    background-color: var(--supplemental-box-background-color, var(--genomics-color-light-grey));
    border-radius: 6px;
    margin-top: var(--denim-spacing-24);
    padding: var(--supplemental-box-padding);
    width: 100%;

    & .header {
        margin: 0;
    }

    & .body {
        padding: 0;

        &:first-child {
            margin-top: var(--denim-spacing-8);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (--denim-breakpoint-desktop) {
        --supplemental-box-padding: var(--denim-spacing-24) var(--denim-spacing-24);
    }
}
