.wavy-line-background {
    --wavy-line-background-image-size: 200%;

    background-color: transparent;
    background-image: url("/images/wavy-lines.svg");
    background-position: top;
    background-size: var(--wavy-line-background-image-size);

    @media (--denim-breakpoint-above-tablet-portrait) {
        --wavy-line-background-image-size: 120%;
    }
}
