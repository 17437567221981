.sidebar-nav-item {
    --sidebar-nav-item-background-color: var(--denim-background-color);
    --sidebar-nav-item-border-radius: var(--denim-border-radius);
    --sidebar-nav-item-color: var(--denim-color-shade-white-alpha-60);
    --sidebar-nav-item-is-active-color: var(--denim-color-shade-white);
    --sidebar-nav-item-padding: var(--denim-spacing-16) 0;

    align-items: center;
    background-color: var(--sidebar-nav-item-background-color);
    border-radius: var(--sidebar-nav-item-border-radius);
    display: grid;
    grid-column-gap: var(--denim-spacing-16);
    grid-template:
        "icon title" auto
        ". description" auto
        / auto 1fr;
    padding: var(--sidebar-nav-item-padding);

    & > .icon {
        align-self: center;
        grid-area: icon;
        opacity: 0.6;
        width: var(--denim-spacing-32);
    }

    & > .title,
    & > .description {
        color: var(--sidebar-nav-item-color);
    }

    & > .title {
        --heading-margin: 0 0 var(--denim-spacing-4) 0;

        align-items: center;
        display: flex;
        grid-area: title;
        justify-content: space-between;
    }

    & > .description {
        grid-area: description;
    }

    &.clickable {
        cursor: pointer;
    }

    &.is-active {
        --sidebar-nav-item-background-color: var(--denim-color-mid-navy);

        & > .icon {
            opacity: 1;
        }

        & > .title {
            color: var(--sidebar-nav-item-is-active-color);
        }
    }

    &:first-of-type {
        margin-top: var(--denim-spacing-8);
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --sidebar-nav-item-padding: var(--denim-spacing-16);

            & > .title {
                & > .icon {
                    display: none;
                }
            }

            & + .sidebar-nav-item {
                margin-top: var(--denim-spacing-4);
            }
        }
    }
}

.account-layout {
    --account-padding-top: var(--denim-spacing-32);

    grid-template-areas:
        "sidebar sidebar"
        "content content";
    padding-top: var(--account-padding-top);

    & > .sidebar {
        grid-area: sidebar;
    }

    & > .content {
        grid-area: content;
    }

    & .user-details {
        margin-bottom: var(--denim-spacing-16);
    }

    &.no-padding {
        --account-padding-top: 0;
    }

    &.mobile {
        grid-template-areas: "content content";

        & .sidebar-nav-item {
            --sidebar-nav-item-border-radius: 0;

            border-bottom: 1px solid var(--denim-color-shade-white-alpha-20);

            & > .icon {
                opacity: 1;
            }

            & > .title {
                color: var(--sidebar-nav-item-is-active-color);
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            grid-template-areas:
                ". sidebar sidebar sidebar sidebar ."
                ". content content content content .";

            &.mobile {
                grid-template-areas: ". content content content content .";
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --account-padding-top: var(--denim-spacing-40);
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            grid-template-areas: "sidebar sidebar sidebar sidebar content content content content content content";
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            grid-template-areas: "sidebar sidebar sidebar sidebar . content content content content content content content";
        }
    }
}

.account-content {
    --account-content-margin-top: var(--denim-spacing-32);

    margin-top: var(--account-content-margin-top);

    & > header {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        & > img {
            margin-right: 12px;
            width: var(--denim-spacing-40);
        }

        & > h2 {
            --heading-margin: 0;
        }

        & ~ .error-card {
            margin-top: var(--denim-spacing-32);
        }
    }

    & .profile-section {
        --profile-section-margin-top: var(--denim-spacing-32);

        margin-top: var(--profile-section-margin-top);

        & > .is-eyebrow {
            color: var(--denim-color-shade-white-alpha-60);
        }

        & .password {
            & > .question {
                color: var(--denim-color-shade-white);
            }

            & > .answer {
                align-items: flex-start;
                color: var(--denim-color-shade-white-alpha-60);
                display: flex;
                flex-flow: column nowrap;
            }
        }

        & .delete-account {
            padding: var(--denim-spacing-24) 0;
        }

        & .button {
            --button-margin: var(--denim-spacing-24) 0 0 0;
        }

        & .info-box {
            margin-top: var(--denim-spacing-24);
        }
    }

    & .test-block {
        --test-block-margin-top: var(--denim-spacing-32);

        margin-top: var(--test-block-margin-top);
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --account-content-margin-top: 0;

            & .profile-section {
                --profile-section-margin-top: var(--denim-spacing-48);
            }

            & .test-block {
                --test-block-margin-top: var(--denim-spacing-48);
            }
        }
    }
}

.lab-report {
    & > p {
        --paragraph-bottom-margin: var(--denim-spacing-24);
        --paragraph-top-margin: var(--denim-spacing-48);
    }

    & > .more-info {
        --more-info-margin: 0 0 var(--denim-spacing-24);
    }
}

.doctors-message {
    & > .content p {
        margin-bottom: var(--denim-spacing-24);
    }

    & .copy-text {
        border: var(--denim-spacing-2) solid var(--denim-color-shade-white-alpha-20);
        border-radius: var(--denim-border-radius);
        padding: var(--denim-spacing-24);
    }
}
