.reports-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    & .header-content {
        --reports-header-padding: var(--denim-spacing-48) 0 calc(var(--denim-spacing-32) + var(--denim-spacing-32));

        padding: var(--reports-header-padding);
    }

    & .eye-of-genomics {
        display: none;
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & .header-content {
                --reports-header-padding: var(--denim-spacing-64) 0 calc(var(--denim-spacing-48) + var(--denim-spacing-32));

                max-width: 50%;
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .header-content {
                max-width: 40%;
            }

            & .eye-of-genomics {
                background-image: url("/images/trait-header-background.svg");
                background-repeat: no-repeat;
                background-size: 360px;
                display: block;
                height: 100%;
                margin-right: calc(-1 * var(--denim-spacing-32));
                width: 360px;
            }
        }
    }

    .trait & {
        & .header-content {
            --reports-header-padding: var(--denim-spacing-48) 0 var(--denim-spacing-32);
        }

        @media (--denim-breakpoint-above-tablet-portrait) {
            & {
                & .header-content {
                    --reports-header-padding: var(--denim-spacing-64) 0 var(--denim-spacing-48);
                }
            }
        }
    }
}

.account-home {
    --back-link-margin-top: 0;

    &.before {
        &::before {
            --icon-before-margin: var(--denim-spacing-4);
        }
    }
}

.report-link {
    --card-padding: 0;
    --card-background-color: var(--denim-color-shade-white);
    --card-border-radius: var(--denim-border-radius);
    --report-link-gradient: var(--denim-gradient-purple-blue);
    --report-link-gradient-width: var(--denim-spacing-8);
    --report-link-padding: var(--denim-spacing-24);

    background-image: var(--report-link-gradient);
    background-repeat: repeat-y;
    background-size: var(--report-link-gradient-width);

    & .shroud {
        background-color: var(--card-background-color);
        border-radius: var(--card-border-radius);
        color: var(--genomics-color-navy);
        margin-left: var(--report-link-gradient-width);
        padding: var(--report-link-padding);

        &:hover {
            cursor: pointer;
        }
    }

    & h4 {
        --heading-margin: 0 0 var(--denim-spacing-4) 0;
    }

    &.higher {
        --report-link-gradient: var(--denim-gradient-orange-pink);
    }

    &.compact {
        --card-background-color: var(--denim-color-shade-off-white);
        --card-border-radius: 0;
        --card-margin: 0;
        --report-link-gradient-width: 2px;
        --report-link-padding: var(--denim-spacing-16) var(--denim-spacing-8) var(--denim-spacing-16) var(--denim-spacing-16);

        & .shroud {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
}

.section-divider {
    --section-divider-border-style: solid;
    --section-divider-border-width: 2px;
    --section-divider-color: var(--denim-color-shade-light-grey);

    border-top: var(--section-divider-border-width) var(--section-divider-border-style) var(--section-divider-color);

    &.bottom {
        border-bottom: var(--section-divider-border-width) var(--section-divider-border-style) var(--section-divider-color);
        border-top: unset;
    }

    &.variant-dark {
        --section-divider-color: var(--denim-color-shade-white-alpha-20);
    }

    .variant-dark & {
        --section-divider-color: var(--denim-color-shade-white-alpha-20);
    }
}

.results {
    & .grid > section {
        grid-column: -1 / 1;
    }

    & .analyse-dna {
        --background-position: center left;
        --card-background-color: var(--denim-color-mid-navy);
        --card-margin: var(--denim-spacing-32) 0 0;
        --card-padding: 0;

        & .grid {
            background-image: url("/images/spiky-circle.svg");
            background-position: var(--background-position);
            background-size: 525px;
            grid-row-gap: var(--denim-spacing-24);
            padding: var(--denim-spacing-32) var(--denim-spacing-16);
        }

        & .left {
            align-self: center;
            display: flex;
            grid-area: left;

            & > .title {
                --heading-margin: 0;
                --title-max-width: 70%;

                max-width: var(--title-max-width);
            }
        }

        & .right {
            display: flex;
            grid-area: right;
            justify-content: flex-start;

            & .button {
                --button-min-width: 100%;
            }
        }
    }

    & .trait {
        --card-background-color: var(--denim-color-shade-white);
        --card-border-radius: 0;
        --card-padding: var(--denim-spacing-24);
        --card-margin: var(--denim-spacing-8) 0;

        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        & .trait-title-container {
            align-items: center;
            border-left: 4px solid var(--denim-color-shade-light-grey);
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 1;
            min-height: 40px;

            & .title {
                padding-left: var(--denim-spacing-16);
            }
        }

        & .view-trait {
            flex-shrink: 1;
        }

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    & ~ .footer {
        --footer-padding: var(--denim-spacing-32) 0;
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & .analyse-dna {
                & .left {
                    & > .title {
                        --title-max-width: 100%;
                    }
                }

                & .right {
                    & .button {
                        --button-min-width: fit-content;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .analyse-dna {
                & .grid {
                    padding: var(--denim-spacing-32) var(--denim-spacing-32);

                    &.fifty-fifty {
                        grid-template:
                            "left left left left left left"
                            "right right right right right right";
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .analyse-dna {
                & .grid {
                    padding: var(--denim-spacing-64) var(--denim-spacing-32);
                }

                & .right {
                    justify-content: flex-end;
                }
            }

            & ~ .footer {
                --footer-padding: var(--denim-spacing-64) 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .analyse-dna {
                & .grid {
                    padding: var(--denim-spacing-64) 0;
                }
            }
        }
    }
}
