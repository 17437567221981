.loading-indicator {
    margin-left: auto;
    margin-right: auto;
    transform: translateY(calc(50% - 50px));
    width: fit-content;

    &.compensate-for-nav {
        margin-top: calc(-1 * var(--ihr-navbar-height));
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            transform: translateY(calc(50% - 15px));
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            transform: translateY(calc(50% - 20px));
        }
    }
}

.loading {
    --loading-image-height: 100px;
    --loading-image-width: 100px;

    height: var(--loading-image-height);
    transition-timing-function: ease-in-out;
    user-select: none;
    width: var(--loading-image-width);

    & .animate-target {
        --loading-animation-duration: 1s;
        --loading-animation-timing-function: ease-in-out;

        animation: loading-rotate var(--loading-animation-duration) var(--loading-animation-timing-function) infinite;
        transform-origin: center;
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --loading-image-height: 30px;
            --loading-image-width: 229px;

            & .animate-target {
                transform-origin: 66px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --loading-image-height: 40px;
            --loading-image-width: 306px;

            & .animate-target {
                transform-origin: 66px;
            }
        }
    }
}

@keyframes loading-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
