.risk-gradient {
    --risk-gradient: var(--denim-gradient-purple-blue);

    background: var(--risk-gradient);

    &.higher {
        --risk-gradient: var(--denim-gradient-orange-pink);
    }

    &.text {
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.explainer-stat {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    & .stat {
        --stat-color: var(--denim-color-shade-white);
        --stat-font-size: 28px;
        --stat-letter-spacing: -0.025em;
        --stat-line-height: 125%;

        align-items: center;
        border-radius: var(--denim-border-radius);
        color: var(--stat-color);
        display: flex;
        flex-shrink: 0;
        font-size: var(--stat-font-size);
        height: 56px;
        justify-content: center;
        letter-spacing: var(--stat-letter-spacing);
        line-height: var(--stat-line-height);
        margin-right: 8px;
        width: 56px;
    }

    &.variant-light {
        & .stat {
            --stat-color: var(--genomics-color-navy);

            border: 2px solid var(--denim-color-shade-light-grey);
        }
    }

    & + .explainer-stat {
        margin-top: 16px;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .stat {
                --stat-font-size: 32px;
            }
        }
    }
}

.trait {
    & .reports-header {
        grid-column: -1 / 1;
    }

    & .card {
        --card-background-color: var(--denim-color-shade-white);
        --card-color: var(--genomics-color-navy);
        --card-padding: 32px 16px;
        --card-margin: 0;

        grid-column: -1 / 1;

        &.variant-dark {
            --card-background-color: var(--genomics-color-navy);
            --card-color: var(--denim-color-shade-off-white);
        }

        &.under-construction {
            --card-background-color: var(--genomics-color-purple);
            --card-color: var(--denim-color-shade-black);
        }
    }

    & .disclaimer {
        color: var(--denim-color-shade-white-alpha-60);
    }

    & section {
        --trait-section-padding: 32px;

        & + section {
            padding-top: var(--trait-section-padding);
        }

        &:not(:last-child) {
            padding-bottom: var(--trait-section-padding);
        }

        &.reports-header {
            padding-bottom: 0;
        }
    }

    & .talk-to-doctor {
        & .talk-to-doctor-content {
            display: flex;
            flex-direction: column;
            gap: var(--denim-spacing-32);
        }

        & .talk-to-doctor-option {
            --talk-to-doctor-option-gap: var(--denim-spacing-16);
            --talk-to-doctor-option-padding: var(--denim-spacing-24) var(--denim-spacing-16);

            align-items: flex-start;
            border: var(--denim-spacing-2) solid var(--denim-color-shade-white-alpha-20);
            border-radius: var(--denim-border-radius);
            display: flex;
            flex-direction: column;
            gap: var(--talk-to-doctor-option-gap);
            padding: var(--talk-to-doctor-option-padding);

            & h4 {
                margin: 0;
            }

            & .step {
                color: var(--denim-color-shade-white-alpha-80);
                margin-bottom: var(--denim-spacing-8);
            }

            & .more-info {
                --more-info-margin: calc(-1 * var(--denim-spacing-8)) 0 0;

                & + .more-info {
                    --more-info-margin: calc(-1 * var(--denim-spacing-16)) 0 0;
                }

                & + .button {
                    --button-margin: calc(-1 * var(--denim-spacing-8)) 0 0;
                }
            }
        }
    }

    & .risk-likelihood {
        & .headline {
            display: grid;
            grid-template:
                "lead"
                "filler"
                "bellcurve"
                "explanation"
                / 1fr;

            & .lead {
                --heading-margin: 0;

                grid-area: lead;
            }

            & .filler {
                grid-area: filler;
            }

            & .bell-curve {
                --align-items: center;
                --bell-curve-background-image: url("/images/bell-curve-MOBILE-HIGHER-risk.svg");

                align-self: end;
                background-image: var(--bell-curve-background-image);
                background-position: bottom;
                background-size: contain;
                grid-area: bellcurve;
                position: relative;

                &.average {
                    --bell-curve-background-image: url("/images/bell-curve-MOBILE-AVERAGE-risk.svg");
                }

                & .sweet {
                    aspect-ratio: 1/1;
                    border-radius: var(--denim-border-radius);
                    color: var(--denim-color-shade-white);
                    font-size: 32px;
                    width: 16%;
                }

                & .stick {
                    --stick-margin-bottom: calc(9% - 1px);

                    aspect-ratio: 1/21.6;
                    border: 2px solid var(--denim-color-shade-white);
                    border-bottom: none;
                    border-top: none;
                    margin: 0 auto var(--stick-margin-bottom);
                    min-width: 5px;
                    width: 2.5%;
                    z-index: 1;
                }

                & .percentiles {
                    width: 100%;

                    & .percentile-box {
                        position: absolute;
                        text-align: center;
                    }
                }
            }

            & .explanation {
                --paragraph-bottom-margin: 0;

                grid-area: explanation;
            }

            & .typical-range {
                display: inline-block;
                margin-top: 4px;
            }
        }

        & p {
            --paragraph-bottom-margin: 24px;
            --paragraph-top-margin: 24px;
        }

        & .understanding {
            display: grid;
            grid-template:
                "information"
                "stats"
                "visualisation"
                / 1fr;

            & .information {
                grid-area: information;

                & p {
                    --paragraph-bottom-margin: 24px;
                    --paragraph-top-margin: 8px;
                }
            }

            & .stats {
                grid-area: stats;
                margin: 0 0 24px;
            }

            & .people {
                align-self: center;
                grid-area: visualisation;
            }
        }

        & .visualisation {
            & img {
                width: 100%;
            }
        }
    }

    & .keep-in-mind {
        --card-margin: 32px 0 0 0;

        & ul {
            --list-bottom-margin: 24px;
            --list-top-margin: 8px;

            & li {
                --list-item-bottom-margin: 8px;
                --list-item-top-margin: 8px;
            }
        }
    }

    & .action-plan {
        --card-padding: 48px 0 0;

        margin-bottom: var(--denim-spacing-32);

        & > .grid {
            --grid-margin: 0;

            grid-template:
                "heading" auto
                "description" auto
                "callout" auto
                / 1fr;
        }

        & .heading {
            --heading-margin: 0;

            grid-area: heading;
        }

        & .description {
            grid-area: description;
        }

        & .info-box {
            grid-area: callout;
        }

        & .about-action-plan {
            --card-margin: 32px 0 0 0;
            --card-padding: 32px 16px;
            --paragraph-bottom-margin: 16px;
            --paragraph-top-margin: 16px;

            & .content {
                & p {
                    --paragraph-bottom-margin: 16px;
                    --paragraph-top-margin: 16px;
                }

                & .heading {
                    --paragraph-bottom-margin: 0;
                    --paragraph-top-margin: 0;
                }
            }

            & .graphic {
                --about-action-plan-margin-top: 32px;

                margin-top: var(--about-action-plan-margin-top);
            }
        }
    }

    /* This can probably get ditched when the stuff after the lifestyle actions is implemented. */
    & .lifestyle-actions {
        margin-bottom: 32px;
    }

    & .learn-more-about {
        position: relative;

        &.grid-container {
            --card-background-color: var(--denim-color-mid-navy);
            --card-margin: 0;
            --card-padding: 40px 16px;
        }

        & > .grid {
            grid-template:
                "content" auto
                / 1fr;

            & > .content {
                grid-area: content;
                z-index: 2;

                & .heading {
                    margin-bottom: 8px;
                }

                & > p {
                    --paragraph-bottom-margin: 16px;
                    --paragraph-top-margin: 16px;
                }
            }
        }

        & .background-image {
            --learn-more-about-background-image-left: -250px;
            --learn-more-about-background-image-top: 4%;

            height: 92%;
            left: var(--learn-more-about-background-image-left);
            position: absolute;
            top: var(--learn-more-about-background-image-top);
        }

        & .link-with-attribution {
            --link-with-attribution-attribution-color: var(--denim-color-shade-white-alpha-80);
        }
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & .risk-likelihood {
                & .headline {
                    grid-template:
                        "lead . bellcurve" auto
                        "filler . bellcurve" 1fr
                        "explanation . bellcurve" auto
                        / 6fr 1fr 5fr;
                }

                & .explanation {
                    --paragraph-top-margin: 0;
                }

                & .understanding {
                    & .stats {
                        margin: 0;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .risk-likelihood {
                & .understanding {
                    grid-template:
                        "information information information" auto
                        "stats . visualisation" auto
                        / 6fr 1fr 5fr;
                }
            }

            & .learn-more-about {
                & .background-image {
                    --learn-more-about-background-image-left: -250px;
                }
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .risk-likelihood {
                & .headline {
                    & .bell-curve {
                        --bell-curve-background-image: url("/images/bell-curve-DESKTOP-HIGHER-risk.svg");

                        &.average {
                            --bell-curve-background-image: url("/images/bell-curve-DESKTOP-AVERAGE-risk.svg");
                        }

                        & .stick {
                            --stick-margin-bottom: calc(1px + 7%);
                        }
                    }
                }

                & .understanding {
                    grid-template:
                        "information . visualisation" auto
                        "stats . visualisation" auto
                        / 6fr 1fr 5fr;
                }
            }

            & .action-plan {
                & > .grid {
                    grid-template:
                        "heading ." auto
                        "description ." auto
                        "callout callout" auto
                        / 1fr 1fr;
                }
            }

            & .learn-more-about {
                & > .grid {
                    grid-template:
                        ". content" auto
                        / 4fr 8fr;
                }

                & .background-image {
                    --learn-more-about-background-image-left: calc(--50%);
                }
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .talk-to-doctor {
                & .talk-to-doctor-option {
                    --talk-to-doctor-option-gap: var(--denim-spacing-24);
                    --talk-to-doctor-option-padding: var(--denim-spacing-32) var(--denim-spacing-24);
                }
            }

            & .card {
                --card-padding: 40px 48px;

                &.action-plan {
                    --card-padding: 64px 0 0;

                    & .about-action-plan {
                        --card-padding: 32px 24px;

                        & .content {
                            margin-right: 32px;
                        }

                        & .graphic {
                            --about-action-plan-margin-top: 0;
                        }
                    }
                }
            }

            & section {
                &.section-divider {
                    --trait-section-padding: 40px;
                }
            }
        }
    }
}
