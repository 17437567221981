.modal {
    --modal-menu-background-color: var(--denim-modal-menu-background-color, rgb(17 0 53));
    --modal-menu-container-offset: var(--denim-spacing-32, 32px);
    --modal-menu-height: var(--denim-modal-menu-height, 100vh);
    --modal-menu-padding: var(--denim-modal-menu-padding, var(--ihr-margin-mobile, 16px));
    --modal-menu-width: calc(var(--denim-modal-menu-width, 100vw) - var(--modal-menu-container-offset));
    --modal-overlay-align-items: var(--denim-modal-overlay-align-items, center);
    --modal-overlay-background-color: var(--denim-modal-overlay-background-color, rgb(255 255 255 / 60%));
    --modal-overlay-bottom: var(--denim-modal-overlay-bottom, 0);
    --modal-overlay-justify-content: var(--denim-modal-overlay-justify-content, center);
    --modal-overlay-left: var(--denim-modal-overlay-left, 0);
    --modal-overlay-right: var(--denim-modal-overlay-right, 0);
    --modal-overlay-top: var(--denim-modal-overlay-top, 0);
    --modal-overlay-z-index: var(--denim-modal-overlay-z-index, 3);

    &.overlay {
        align-items: var(--modal-overlay-align-items);
        background-color: var(--modal-overlay-background-color);
        display: flex;
        flex-flow: column;
        inset: var(--modal-overlay-top) var(--modal-overlay-right) var(--modal-overlay-bottom) var(--modal-overlay-left);
        justify-content: var(--modal-overlay-justify-content);
        position: fixed;
        z-index: var(--modal-overlay-z-index);
    }

    & .menu {
        background-color: var(--modal-menu-background-color);
        height: var(--modal-menu-height);
        margin-left: var(--modal-menu-container-offset);
        padding: var(--modal-menu-padding);
        width: var(--modal-menu-width);
    }

    & .dialog {
        --modal-content-margin: -12px 8px 0;
        --modal-content-padding: 24px;

        background-color: var(--modal-dialog-background-color);
        border-radius: 6px;
        margin: var(--modal-content-margin);
        max-width: 520px;
        mix-blend-mode: var(--modal-dialog-background-mix-blend-mode);
        outline: none;
        padding: var(--modal-content-padding);
        user-select: none;
    }

    & .question {
        align-items: flex-start;
        display: flex;
        flex-flow: row nowrap;

        & .text {
            --heading-margin: 0;
        }

        & .icon {
            --icon-size: 26px;

            cursor: pointer;
            margin-left: 16px;
        }
    }

    & .divider {
        --divider-margin: 24px 0;
    }

    & .actions {
        --modal-actions-align-items: center;
        --modal-actions-flex-flow: column nowrap;
        --modal-actions-justify-content: space-between;

        align-items: var(--modal-actions-align-items);
        display: flex;
        flex-flow: var(--modal-actions-flex-flow);
        justify-content: var(--modal-actions-justify-content);

        & .continue-action {
            margin-bottom: 24px;
            order: 0;
        }

        & .cancel-action {
            order: 1;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            --modal-menu-padding: var(--denim-spacing-16) var(--denim-spacing-32);
            --modal-menu-width: 360px;

            & .menu {
                margin-left: auto;

                & .content {
                    & button {
                        --button-min-width: 100%;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .dialog {
                --modal-content-margin: -12px 0 0;
            }

            & .actions {
                --modal-actions-align-items: center;
                --modal-actions-flex-flow: row nowrap;
                --modal-actions-justify-content: space-between;

                & .continue-action {
                    margin-bottom: unset;
                    order: unset;
                }

                & .cancel-action {
                    order: unset;
                }
            }
        }
    }
}
