.results-how-we-calculate-risk {
    & .know-more {
        --card-margin: 0;
        --card-padding: var(--denim-spacing-56) var(--denim-spacing-16) var(--denim-spacing-24);

        & > .grid {
            & > .content {
                grid-area: content;

                & > :not(:last-child) {
                    margin-bottom: var(--denim-spacing-24);
                }

                & h4 {
                    --heading-margin: 0 0 var(--denim-spacing-8);
                }

                & .ask-a-doctor {
                    margin-top: var(--denim-spacing-8);
                }
            }
        }
    }

    & .explanatory-step {
        --card-background-color: transparent;
        --card-margin: 0;
        --card-padding: var(--denim-spacing-24) var(--denim-spacing-16) 0;

        & + .explanatory-step {
            --card-padding: var(--denim-spacing-32) var(--denim-spacing-16) 0;
        }

        &.extra-bottom-padding {
            --card-padding: var(--denim-spacing-32) var(--denim-spacing-16) var(--denim-spacing-24);
        }

        & .with-bullet-point {
            align-items: flex-start;
            display: flex;
            gap: 8px;

            & .bullet-point {
                margin-top: 7px;

                &.bell-curve-bullet-point {
                    background-color: var(--genomics-color-pink);
                    color: var(--denim-color-shade-white);
                    margin-top: 0;
                }
            }
        }

        & > .grid {
            & > .content {
                --content-gap: var(--denim-spacing-16);

                display: flex;
                flex-direction: column;
                gap: var(--content-gap);

                &:first-child {
                    margin-top: 0;
                }

                & h4 {
                    --heading-margin: 0;
                }

                & > p {
                    --paragraph-bottom-margin: 0;
                    --paragraph-top-margin: 0;
                }

                & ul {
                    --list-bottom-margin: 0;
                    --list-top-margin: 0;

                    & li {
                        --list-item-bottom-margin: 0;
                        --list-item-top-margin: 0;
                    }
                }

                & img {
                    border-radius: var(--denim-border-radius);
                    width: 100%;
                }

                & .button {
                    width: fit-content;
                }

                & :last-child {
                    margin-bottom: 0;
                }

                & .supplemental {
                    --card-background-color: var(--denim-color-mid-navy);
                    --card-padding: var(--denim-spacing-16);

                    display: flex;
                    flex-direction: column;
                    gap: var(--denim-spacing-8);

                    & p {
                        --paragraph-bottom-margin: 0;
                        --paragraph-top-margin: 0;
                    }
                }

                & .more-info {
                    --more-info-margin: calc(-1 * var(--denim-spacing-8)) 0 0;

                    & + p {
                        --paragraph-top-margin: calc(-1 * var(--denim-spacing-8));
                    }
                }
            }
        }
    }

    & .how-we-calculate-risk-title {
        --card-margin: 0;
        --card-padding: var(--denim-spacing-48) var(--denim-spacing-16) var(--denim-spacing-32);

        background: transparent;

        & .left {
            grid-area: left;
            position: relative;
        }
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & .explanatory-step {
                --card-padding: var(--denim-spacing-24) var(--denim-spacing-32);

                & + .explanatory-step {
                    --card-padding: var(--denim-spacing-32) var(--denim-spacing-32) 0;
                }

                &.extra-bottom-padding {
                    --card-padding: var(--denim-spacing-32) var(--denim-spacing-32) var(--denim-spacing-96);
                }

                & > .grid {
                    & > .content {
                        --content-gap: var(--denim-spacing-24);
                    }
                }
            }

            & .how-we-calculate-risk-title {
                --card-padding: var(--denim-spacing-48) var(--denim-spacing-32) var(--denim-spacing-32);
            }

            & .know-more {
                --card-padding: var(--denim-spacing-56) var(--denim-spacing-32) var(--denim-spacing-24);
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .explanatory-step {
                --card-padding: var(--denim-spacing-40) 0 0;

                & > .grid {
                    & > .content {
                        & .supplemental {
                            --card-padding: var(--denim-spacing-24);

                            gap: var(--denim-spacing-16);
                        }
                    }
                }

                & + .explanatory-step {
                    --card-padding: var(--denim-spacing-48) 0 0;
                }

                &.extra-bottom-padding {
                    --card-padding: var(--denim-spacing-48) 0 var(--denim-spacing-96);
                }
            }

            & .how-we-calculate-risk-title {
                --card-padding: var(--denim-spacing-64) 0 var(--denim-spacing-48);
            }

            & .know-more {
                --card-padding: var(--denim-spacing-88) 0 var(--denim-spacing-96);

                & > .grid {
                    & > .content {
                        & > :not(:last-child) {
                            margin-bottom: var(--denim-spacing-40);
                        }

                        & h4 {
                            --heading-margin: 0 0 var(--denim-spacing-16);
                        }
                    }
                }
            }
        }
    }
}
