.error-notice {
    & h1 {
        --heading-margin: 0 0 var(--denim-spacing-32);
    }

    & p {
        --paragraph-top-margin: 0;
    }

    & .error-code {
        color: var(--denim-color-shade-white-alpha-60);
    }

    & img {
        width: 100%;
    }
}
