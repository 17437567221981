.test-not-tell {
    --card-background-color: var(--denim-color-mid-navy) !important; /* stylelint-disable-line declaration-no-important */
    --card-margin: 0;
    --card-padding: var(--denim-spacing-40) var(--denim-spacing-16) var(--denim-spacing-48) !important; /* stylelint-disable-line declaration-no-important */

    & .sidebar {
        margin-bottom: var(--denim-spacing-24) !important; /* stylelint-disable-line declaration-no-important */

        & h2 {
            margin-bottom: 0;
        }
    }

    & ul {
        --list-top-margin: 0;
        --list-bottom-margin: 0;

        & li:last-child {
            --list-item-bottom-margin: 0 !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            --card-padding: var(--denim-spacing-40) var(--denim-spacing-32) var(--denim-spacing-32) !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --card-padding: var(--denim-spacing-88) var(--denim-spacing-32) var(--denim-spacing-96) !important; /* stylelint-disable-line declaration-no-important */

            & ul {
                & li {
                    --list-item-bottom-margin: var(--denim-spacing-24) !important; /* stylelint-disable-line declaration-no-important */
                }
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --card-padding: var(--denim-spacing-88) 0 var(--denim-spacing-96) !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}
