.page-content {
    --page-content-background-color: transparent;
    --page-content-container-child-margin: var(--denim-spacing-40) 0 0;
    --page-content-container-margin: 0 var(--denim-spacing-16);
    --page-content-container-padding: 0;
    --page-content-margin: 0;
    --page-content-padding: var(--denim-spacing-40) 0;

    background-color: var(--page-content-background-color);
    margin: var(--page-content-margin);
    padding: var(--page-content-padding);

    &.page-title {
        --page-content-padding: var(--denim-spacing-48) 0 var(--denim-spacing-32);
    }

    &.no-vertical-padding {
        --page-content-padding: 0;
    }

    &.mid-navy-gradient {
        --page-content-background-color: var(--denim-color-mid-navy);

        background: var(--ihr-mid-navy-background);
    }

    & > .container {
        margin: var(--page-content-container-margin);
        max-width: var(--denim-grid-max-width, 1072px);
        padding: var(--page-content-container-padding);

        & > * + * {
            margin: var(--page-content-container-child-margin);
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            --page-content-container-child-margin: var(--denim-spacing-48) 0 0;
            --page-content-container-margin: 0 var(--denim-spacing-24);
            --page-content-padding: var(--denim-spacing-48) 0;

            &.page-title {
                --page-content-padding: var(--denim-spacing-56) 0 var(--denim-spacing-40);
            }

            &.no-vertical-padding {
                --page-content-padding: 0;
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --page-content-container-child-margin: var(--denim-spacing-56) 0 0;
            --page-content-container-margin: 0 var(--denim-spacing-32);
            --page-content-padding: var(--denim-spacing-56) 0;

            &.page-title {
                --page-content-padding: var(--denim-spacing-64) 0 var(--denim-spacing-48);
            }

            &.no-vertical-padding {
                --page-content-padding: 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --page-content-container-margin: 0 auto;
        }
    }
}

.page-title {
    & > h1 {
        margin: 0 0 var(--denim-spacing-32);
    }

    & > p {
        margin: 0;
    }
}

.two-thirds-container {
    --two-thirds-container-flex-direction: column;
    --two-thirds-container-one-third-margin: 0 0 var(--denim-spacing-40);

    display: flex;
    flex-direction: var(--two-thirds-container-flex-direction);
    margin: 0;
    padding: 0;
    width: 100%;

    & > .one-third {
        margin: var(--two-thirds-container-one-third-margin);

        & :--heading {
            --heading-padding: 0;
            --heading-margin: 0;
        }
    }

    & > .two-thirds {
        & .flex-column-to-row {
            --flex-column-to-row-margin: 0 0 var(--denim-spacing-24);

            margin: var(--flex-column-to-row-margin);

            &:last-child {
                --flex-column-to-row-margin: 0;
            }
        }

        & p {
            --paragraph-bottom-margin: var(--denim-spacing-16);
            --paragraph-top-margin: 0;

            &:last-child {
                --paragraph-bottom-margin: 0;
            }
        }

        & img {
            border-radius: var(--denim-border-radius);
            margin: 0 0 var(--denim-spacing-24);
            width: 100%;

            &:last-child {
                margin: 0;
            }
        }

        & .link-with-attribution {
            margin: 0 0 var(--denim-spacing-24);

            &:last-child {
                margin: 0;
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --two-thirds-container-flex-direction: row;
            --two-thirds-container-one-third-margin: 0 var(--denim-spacing-32) 0 0;

            & > .one-third {
                flex-grow: 1;
                width: 34%;
            }

            & > .two-thirds {
                flex-grow: 2;
                width: 66%;

                & p {
                    --paragraph-bottom-margin: var(--denim-spacing-24);

                    &.half-margin {
                        --paragraph-bottom-margin: var(--denim-spacing-16);
                    }
                }
            }
        }
    }
}

.six-five-grid-container {
    --six-five-grid-container-align-items: start;
    --six-five-grid-container-column-gap: var(--denim-spacing-16);
    --six-five-grid-container-row-gap: var(--denim-spacing-24);

    align-items: var(--six-five-grid-container-align-items);
    display: grid;
    gap: var(--six-five-grid-container-row-gap) var(--six-five-grid-container-column-gap);
    grid-template:
        "six six" auto
        "five five" auto
        / 1fr 1fr;

    & > .five {
        grid-area: five;
    }

    & > .six {
        grid-area: six;

        &.image {
            border-radius: var(--denim-border-radius);
        }
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            --six-five-grid-container-align-items: center;
            --grid-column-gap: var(--denim-spacing-32);
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            grid-template:
                "six six six five five five" auto
                / 1fr 1fr 1fr 1fr 1fr 1fr;

            &.reversed {
                grid-template:
                    "five five five six six six" auto
                    / 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            grid-template:
                "six six six six six six . five five five five five" auto
                / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

            &.reversed {
                grid-template:
                    "five five five five five . six six six six six six" auto
                    / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }
}

.bullet-point {
    --bullet-point-margin: 0 0 var(--denim-spacing-8);

    align-items: center;
    background-color: var(--denim-color-shade-white-alpha-20);
    border-radius: 50%;
    color: var(--genomics-color-light-green);
    display: flex;
    flex-shrink: 0;
    font-size: var(--denim-spacing-16);
    height: var(--denim-spacing-24);
    justify-content: center;
    margin: var(--bullet-point-margin);
    width: var(--denim-spacing-24);

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --bullet-point-margin: 0 var(--denim-spacing-16) 0 0;

            .large > & {
                --bullet-point-margin: 6px var(--denim-spacing-16) 0 0;
            }
        }
    }
}

.with-checkmark {
    align-items: flex-start;
    display: flex;
}

.example-bell-curve {
    --example-bell-curve-align-items: flex-start;
    --example-bell-curve-flex-direction: column;
    --example-bell-curve-padding: var(--denim-spacing-32) var(--denim-spacing-16) var(--denim-spacing-40);

    align-items: var(--example-bell-curve-align-items);
    background: var(--denim-gradient-purple-blue);
    border-radius: var(--denim-border-radius);
    color: var(--denim-color-shade-white);
    display: flex;
    flex-direction: var(--example-bell-curve-flex-direction);
    gap: var(--denim-spacing-32);
    padding: var(--example-bell-curve-padding);

    & h4 {
        --heading-margin: 0;
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --example-bell-curve-align-items: center;
            --example-bell-curve-flex-direction: row;
            --example-bell-curve-padding: var(--denim-spacing-32) 0;

            justify-content: center;

            & h4 {
                flex-shrink: 0;
                width: 33%;
            }

            & img {
                width: 334px !important; /* stylelint-disable-line declaration-no-important */
            }
        }
    }
}

.does-not-tell-you {
    & li {
        --list-item-bottom-margin: 0;
        --list-item-top-margin: 0;

        & + li {
            --list-item-top-margin: var(--denim-spacing-16);
        }
    }
}
