.account-menu {
    --account-menu-active-item-border: var(--ihr-active-link, var(--denim-active-menu-item, 2px solid var(--genomics-color-light-green, rgb(51 255 196))));
    --account-menu-active-item-padding: var(--denim-spacing-16);
    --account-menu-background-color: var(--denim-color-mid-navy, rgb(35 0 112));
    --account-menu-border-radius: var(--denim-border-radius, 8px);
    --account-menu-items-spacing: var(--denim-spacing-16);
    --account-menu-margin: 0;
    --account-menu-padding: var(--denim-spacing-16);

    background-color: var(--account-menu-background-color);
    border-radius: var(--account-menu-border-radius);
    margin: var(--account-menu-margin);
    padding: var(--account-menu-padding);

    & > ul {
        --list-bottom-margin: 0;
        --list-padding-left: 0;
        --list-top-margin: 0;

        list-style: none;

        & > li {
            --list-item-bottom-margin: var(--account-menu-items-spacing);
            --list-item-top-margin: 0;

            &.name {
                color: var(--denim-color-shade-white-alpha-60);
            }

            &:last-child {
                --list-item-bottom-margin: 0;
            }

            & .active {
                border-left: var(--account-menu-active-item-border);
                padding-left: var(--account-menu-active-item-padding);
            }
        }
    }

    .navbar-mobile & {
        --account-menu-border-radius: 0;
        --account-menu-items-spacing: var(--denim-spacing-24);
        --account-menu-margin: 0 calc(var(--denim-spacing-16) * -1) var(--denim-spacing-32);
    }
}
