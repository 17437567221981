.access-code-input {
    --access-code-input-border-color: var(--ihr-access-code-input-border-color, rgb(51 255 196));
    --access-code-input-border-radius: var(--ihr-access-code-input-border-radius, 0);
    --access-code-input-border-style: var(--ihr-access-code-input-border-style, solid);
    --access-code-input-border-width: var(--ihr-access-code-input-border-width, 2px);
    --access-code-input-label-color: var(--ihr-access-code-input-label-color, rgb(255 255 255));

    & .labels {
        --form-group-label-color: var(--access-code-input-label-color);
    }

    & .form-group {
        & .is-error {
            margin-top: 8px;
        }
    }

    & .controls {
        --access-code-input-controls-align-items: flex-start;
        --access-code-input-controls-flex-direction: column;
        --access-code-input-controls-justify-content: center;

        align-items: var(--access-code-input-controls-align-items);
        display: flex;
        flex-flow: var(--access-code-input-controls-flex-direction) nowrap;
        justify-content: var(--access-code-input-controls-justify-content);
        margin-bottom: 0;
    }

    & .text-input {
        --text-input-border-color: var(--access-code-input-border-color);
        --text-input-border-radius: var(--access-code-input-border-radius);
        --text-input-border-style: var(--access-code-input-border-style);
        --text-input-border-width: var(--access-code-input-border-width);
        --text-input-box-shadow-blur-radius: 0;
        --text-input-box-shadow-spread-radius: 0;
    }

    & .button {
        --button-border-width: var(--access-code-input-border-width);
        --button-margin-top: 8px;
        --button-min-width: 100%;
        --denim-button-padding: 9px 16px;

        margin-top: var(--button-margin-top);
        min-width: var(--button-min-width);
        outline: none;
        word-break: keep-all;
    }

    &.variant-light {
        --access-code-input-border-color: var(--ihr-access-code-input-secondary-border-color, rgb(13 94 255));
        --access-code-input-label-color: var(--ihr-access-code-input-secondary-label-color, var(--genomics-color-primary-navy, rgb(17 0 53)));

        & .text-input {
            &.invalid {
                --text-input-border-color: var(--access-code-input-border-color);
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .controls {
                --access-code-input-controls-align-items: center;
                --access-code-input-controls-flex-direction: row;
                --access-code-input-controls-justify-content: flex-start;
            }

            & .text-input {
                /** Without this, the text input is 4px to big vertically. */
                line-height: 22px;
            }

            & .button {
                --button-margin-top: 0;
                --button-min-width: fit-content;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .text-input {
                /** Without this, the text input is 2px to small vertically. */
                line-height: 24px;
            }
        }
    }
}
