.marketing-equalizer {
    --marketing-equalizer-height: var(--denim-marketing-equalizer-height-on-mobile, 296px);
    --marketing-equalizer-image-opacity-faded: var(--denim-marketing-equalizer-image-opacity-faded, 0.2);
    --marketing-equalizer-margin: var(--denim-marketing-equalizer-margin-on-mobile, var(--denim-spacing-16, 16px) 0);
    --marketing-equalizer-transition-duration: var(--denim-marketing-equalizer-transition-duration, 2000ms);
    --marketing-equalizer-transition-timing-function: var(--denim-marketing-equalizer-transition-timing-function, cubic-bezier(0.4, 0, 0.08, 1));

    height: var(--marketing-equalizer-height);
    /* stylelint-disable-next-line declaration-no-important */
    margin: var(--marketing-equalizer-margin) !important;
    position: relative;
    width: 100%;

    & > .play-pause {
        background: var(--denim-color-shade-white-alpha-20, rgb(255 255 255 / 20%));
        border: none;
        border-radius: 100px;
        box-shadow: 0 0 var(--denim-spacing-4, 4px) var(--denim-color-shade-white-alpha-60, rgb(255 255 255 / 60%));
        cursor: pointer;
        display: grid;
        padding: var(--denim-spacing-8, 8px);
        place-items: center center;
        position: absolute;
        right: var(--denim-spacing-16, 16px);
        top: var(--denim-spacing-16, 16px);
        transition: background 300ms ease-in-out;
        z-index: 2;

        &:hover {
            background: var(--denim-color-shade-white, rgb(255 255 255));
        }

        & img {
            height: var(--denim-spacing-16, 16px);
            width: var(--denim-spacing-16, 16px);
        }
    }

    & > .images {
        --hero-carousel-grid-template-columns: 60% 12% 12% 10%;

        display: grid;
        gap: 2%;
        grid-template-columns: var(--hero-carousel-grid-template-columns);
        height: 100%;
        transition: grid-template-columns var(--marketing-equalizer-transition-duration) var(--marketing-equalizer-transition-timing-function);

        & > .image {
            background-position: 50% 50%;
            background-size: cover;
            border-radius: var(--denim-spacing-8, 8px);
            height: 100%;
            opacity: var(--marketing-equalizer-image-opacity-faded);
            transition: opacity var(--marketing-equalizer-transition-duration) var(--marketing-equalizer-transition-timing-function);
        }
    }

    &.step-0 {
        & > .images {
            & > .image-0 {
                opacity: 1;
            }
        }
    }

    &.step-1 {
        & > .images {
            --hero-carousel-grid-template-columns: 10% 60% 12% 12%;

            & > .image-1 {
                opacity: 1;
            }
        }
    }

    &.step-2 {
        & > .images {
            --hero-carousel-grid-template-columns: 12% 10% 60% 12%;

            & > .image-2 {
                opacity: 1;
            }
        }
    }

    &.step-3 {
        & > .images {
            --hero-carousel-grid-template-columns: 12% 12% 10% 60%;

            & > .image-3 {
                opacity: 1;
            }
        }
    }

    .landing-page & {
        grid-area: image;
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            --marketing-equalizer-height: var(--denim-marketing-equalizer-height-above-mobile, 428px);
            --marketing-equalizer-margin: var(--denim-marketing-equalizer-margin-above-mobile, var(--denim-spacing-16, 16px) 0 0);
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            --marketing-equalizer-height: var(--denim-marketing-equalizer-height-above-tablet-portrait, 612px);
            --marketing-equalizer-margin: var(--denim-marketing-equalizer-margin-above-tablet-portrait, 0);
        }
    }

    @media (prefers-reduced-motion: reduce) {
        & {
            --marketing-equalizer-transition-duration: var(--denim-marketing-equalizer-transition-duration-reduced-motion, 0ms);
            --marketing-equalizer-transition-timing-function: var(--denim-marketing-equalizer-transition-timing-function-reduced-motion, linear);
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        & {
            --marketing-equalizer-transition-duration: var(--denim-marketing-equalizer-transition-duration, 2000ms);
            --marketing-equalizer-transition-timing-function: var(--denim-marketing-equalizer-transition-timing-function, cubic-bezier(0.4, 0, 0.08, 1));
        }
    }
}

.risk-overlay {
    --risk-overlay-scale: 0.85;
    --risk-overlay-transition-duration: var(--denim-marketing-equalizer-transition-duration, 2000ms);
    --risk-overlay-transition-timing-function: var(--denim-marketing-equalizer-transition-timing-function, cubic-bezier(0.4, 0, 0.08, 1));

    animation: fade-in-animation 2s 1s forwards;
    backdrop-filter: blur(var(--denim-spacing-4, 4px));
    background-color: var(--genomics-color-navy-alpha-80, rgb(17 0 53 / 80%));
    border-radius: var(--denim-border-radius, var(--denim-spacing-8, 8px));
    bottom: 4%;
    opacity: 0;
    padding: var(--denim-spacing-16, 16px);
    position: absolute;
    text-rendering: geometricprecision;
    transform: translateX(calc((100 / 54) * 4%)) scale(var(--risk-overlay-scale));
    transform-origin: 0 100%;
    transition: transform var(--risk-overlay-transition-duration) var(--risk-overlay-transition-timing-function);
    width: 57%;
    z-index: 2;

    & p {
        margin: 0;
        padding: 0;
    }

    & .truncate-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & .is-secondary-color {
        color: var(--denim-color-shade-white-alpha-60, rgb(255 255 255 / 60%));
    }

    & .text-clip-box {
        overflow: hidden;

        & > p {
            transform: translateY(0%);
            transition: transform calc(var(--risk-overlay-transition-duration) / 2) var(--risk-overlay-transition-timing-function);
        }

        &.transitioning {
            & > p {
                transform: translateY(100%);
            }
        }
    }

    & .risk-results {
        margin-top: var(--denim-spacing-8, 8px);
    }

    & .risk-result {
        align-items: start;
        display: grid;
        gap: var(--denim-spacing-8, 8px);
        grid-template-columns: var(--denim-spacing-24, 24px) 1fr;
        margin: var(--denim-spacing-8, 8px) 0 0;

        & .risk-count {
            border-radius: var(--denim-spacing-4, 4px);
            display: grid;
            height: var(--denim-spacing-24, 24px);
            place-items: center center;
            width: var(--denim-spacing-24, 24px);

            &.typical-risk {
                background: var(--denim-gradient-purple-blue, linear-gradient(55.54deg, var(--genomics-color-purple, rgb(124 37 223)) -67.53%, var(--genomics-color-bright-blue, rgb(13 94 255)) 130.52%));
            }

            &.increased-risk {
                background: var(--denim-gradient-orange-pink, linear-gradient(225deg, var(--genomics-color-orange, rgb(255 83 1)) 30.68%, var(--genomics-color-pink, rgb(252 64 130)) 100%));
            }
        }

        & .risk-label {
            /* min-height and min-width needed here to make the div occupy the space without overflowing - and so get
               the text truncation to work properly */
            min-height: 0;
            min-width: 0;
            padding-top: var(--denim-spacing-2, 2px);
        }
    }

    /* "57" as a magic number here is to match the percentage width of the result-box. */
    .step-1 & {
        transform: translateX(calc((100 / 57) * 16%)) scale(var(--risk-overlay-scale));
    }

    .step-2 & {
        transform: translateX(calc((100 / 57) * 30%)) scale(var(--risk-overlay-scale));
    }

    .step-3 & {
        transform: translateX(calc((100 / 57) * 44%)) scale(var(--risk-overlay-scale));
    }

    @media (--denim-breakpoint-above-mobile) {
        --risk-overlay-scale: 0.9;
    }

    @media (prefers-reduced-motion: reduce) {
        & {
            --risk-overlay-transition-duration: var(--denim-marketing-equalizer-transition-duration-reduced-motion, 0ms);
            --risk-overlay-transition-timing-function: var(--denim-marketing-equalizer-transition-timing-function-reduced-motion, linear);

            /* stylelint-disable declaration-no-important */
            animation: none !important;
            opacity: 1 !important;

            & .text-clip-box {
                &.transitioning {
                    & > p {
                        transform: translateY(0%) !important;
                    }
                }
            }
            /* stylelint-enable declaration-no-important */
        }
    }
}

@keyframes fade-in-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
