.grid-container {
    --card-background-color: var(--genomics-color-navy, rgb(17 0 53));
    --card-border-radius: 0;
    --card-color: var(--denim-color-shade-white, rgb(255 255 255));

    & .grid {
        --grid-margin: 0 auto;

        &.all-columns {
            & > * {
                grid-column: -1 / 1;
            }
        }
    }

    &.variant-light {
        --card-background-color: var(--denim-color-shade-white, rgb(255 255 255));
        --card-color: var(--genomics-color-navy, rgb(17 0 53));

        & :--heading {
            --heading-color: var(--genomics-color-navy, rgb(17 0 53));
        }

        & .link {
            --link-color: var(--genomics-color-bright-blue, rgb(13 94 255));
        }
    }

    &.variant-none {
        --card-border: 0;
        --card-background-color: transparent;
    }
}
