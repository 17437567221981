.tracking-info {
    --tracking-info-background-color: var(--ihr-color-light-navy, rgb(50 0 156));
    --tracking-info-color: var(--denim-color-shade-white, rgb(255 255 255));

    align-items: flex-start;
    background-color: var(--tracking-info-background-color);
    color: var(--tracking-info-color);
    display: flex;

    &.before::before {
        --icon-before-margin: 16px;
    }

    & a {
        margin-top: 16px;
    }

    & h3 {
        margin-bottom: 0;
    }

    & p {
        margin-bottom: 0;
        margin-top: 0;
    }
}
