.with-sign-up {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > .button {
        --button-min-width: fit-content;

        margin: 0 var(--denim-spacing-16) 0 0;
    }
}

.about-us {
    & .projects {
        & img {
            height: 106px;
            width: 106px;
        }
    }

    & .people {
        & p {
            --paragraph-bottom-margin: var(--denim-spacing-8);
            --paragraph-top-margin: 0;
        }

        & img {
            height: 164px;
            margin: 0 0 var(--denim-spacing-16);
            width: 164px;
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .projects {
                & .flex-column-to-row {
                    align-items: center;
                }

                & img {
                    height: 120px;
                    margin: 0 var(--denim-spacing-24) 0 0;
                    width: 120px;
                }
            }

            & .people {
                & .flex-column-to-row {
                    align-items: flex-start;
                }

                & p {
                    --paragraph-bottom-margin: var(--denim-spacing-16);
                    --paragraph-top-margin: 0;
                }

                & img {
                    height: 150px;
                    margin: 0 var(--denim-spacing-24) 0 0;
                    width: 150px;
                }
            }
        }
    }
}

.science {
    & .our-performance {
        & .more-info {
            --more-info-margin: var(--denim-spacing-24) 0 0;
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .does-not-tell-you {
                & li {
                    & + li {
                        --list-item-top-margin: var(--denim-spacing-24);
                    }
                }
            }

            & .our-performance {
                & .more-info {
                    --more-info-margin: var(--denim-spacing-48) 0 0;
                }
            }
        }
    }
}

.cost-to-you {
    --modal-overlay-background-color: var(--genomics-color-navy-alpha-80);

    & .dialog {
        --modal-content-padding: var(--denim-spacing-40);

        & :--heading {
            --heading-margin: 0;
        }

        & p {
            --paragraph-bottom-margin: var(--denim-spacing-24);
            --paragraph-top-margin: var(--denim-spacing-16);
        }
    }

    & .actions {
        --modal-actions-align-items: flex-start;
    }

    & button {
        --button-min-width: fit-content;
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .dialog {
                --modal-content-padding: var(--denim-spacing-48);
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .dialog {
                --modal-content-padding: var(--denim-spacing-56);
            }
        }
    }
}

.landing-page {
    & .page-content {
        &.page-title {
            --page-content-padding: 0 0 var(--denim-spacing-40);
        }
    }

    & :--list {
        --list-bottom-margin: var(--denim-spacing-24);
        --list-padding-left: 0;
        --list-top-margin: var(--denim-spacing-24);

        list-style: none;

        & > li {
            --list-item-bottom-margin: 0;
            --list-item-top-margin: 0;

            &.multiline {
                align-items: flex-start;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }

            & + li {
                --list-item-top-margin: var(--denim-spacing-16);
            }
        }
    }

    & .six-five-grid-container {
        & :--heading {
            --heading-margin: 0;
        }

        & p {
            --paragraph-bottom-margin: 0;
            --paragraph-top-margin: 0;
        }

        & > img {
            width: 100%;
        }
    }

    & button {
        --button-min-width: fit-content;
    }

    & .take-action {
        & :--list {
            --list-bottom-margin: 0;
        }
    }

    & .sign-up-middle {
        --sign-up-middle-align-items: flex-start;
        --sign-up-middle-flex-direction: column;

        align-items: var(--sign-up-middle-align-items);
        display: flex;
        flex-direction: var(--sign-up-middle-flex-direction);

        & :--heading {
            --heading-margin: 0 0 var(--denim-spacing-24);
        }
    }

    & .saliva-test {
        & .button {
            --button-margin: 0 0 var(--denim-spacing-24);
        }
    }

    & .hero {
        --grid-row-gap: var(--denim-spacing-8);

        grid-template-areas:
            "image image"
            "content content";

        & .hero-content {
            grid-area: content;

            & h1 {
                --heading-margin: 0 0 var(--denim-spacing-16);
            }

            & p {
                --paragraph-bottom-margin: var(--denim-spacing-24);
                --paragraph-top-margin: 0;

                &:last-child {
                    --paragraph-bottom-margin: 0;
                }
            }

            & .button {
                --button-margin: 0 0 var(--denim-spacing-24);
            }
        }

        & .animated-image {
            --hero-graphic-width: 100%;

            border-radius: 50%;
            grid-area: image;
            margin: 0 !important; /* stylelint-disable-line declaration-no-important */
            width: var(--hero-graphic-width);
        }
    }

    & .bullets {
        & .content {
            --bullets-flex-direction: column;

            display: flex;
            flex-direction: var(--bullets-flex-direction);
        }

        & .point {
            align-items: flex-start;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;

            & :--heading {
                --heading-margin: 0;
            }

            /* stylelint-disable no-descending-specificity */
            & p {
                --paragraph-bottom-margin: 0;
                --paragraph-top-margin: var(--denim-spacing-4);
            }
            /* stylelint-enable no-descending-specificity */

            & + .point {
                margin: var(--denim-spacing-24) 0 0;
            }
        }

        & img {
            height: var(--denim-spacing-24);
            margin: 0 var(--denim-spacing-8) 0 0;
            width: var(--denim-spacing-24);
        }
    }

    & .why-link {
        display: inline-block;
    }

    & .secure {
        --secure-padding: var(--denim-spacing-40) var(--denim-spacing-16);

        border-radius: var(--denim-border-radius);
        padding: var(--secure-padding);

        & :--heading {
            --heading-margin: 0;

            align-items: center;
            display: flex;

            & img {
                margin: 0 var(--denim-spacing-8) 0 0;
            }
        }

        &.mid-navy-gradient {
            background: var(--ihr-mid-navy-background);
        }
    }

    /* stylelint-disable no-descending-specificity */
    & .about-us {
        & .content > p {
            --paragraph-bottom-margin: var(--denim-spacing-24);
            --paragraph-top-margin: var(--denim-spacing-16);
        }

        & .people {
            --people-align-items: flex-start;
            --people-flex-direction: column;

            align-items: var(--people-align-items);
            display: flex;
            flex-direction: var(--people-flex-direction);
        }

        & .person {
            --person-align-items: center;
            --person-flex-direction: row;
            --person-img-dimenssion: 108px;
            --person-img-margin: 0 var(--denim-spacing-8) 0 0;

            align-items: var(--person-align-items);
            display: flex;
            flex-direction: var(--person-flex-direction);

            & > img {
                border-radius: var(--denim-border-radius);
                height: var(--person-img-dimenssion);
                margin: var(--person-img-margin);
                width: var(--person-img-dimenssion);
            }

            & p {
                --paragraph-bottom-margin: 0;
                --paragraph-top-margin: 0;

                & + p {
                    --paragraph-top-margin: var(--denim-spacing-2);
                }
            }

            & + .person {
                --person-img-margin: var(--denim-spacing-8) var(--denim-spacing-8) 0 0;
            }
        }
    }
    /* stylelint-enable no-descending-specificity */

    & .accuracy {
        /* stylelint-disable no-descending-specificity */
        & .five {
            & p {
                --paragraph-bottom-margin: var(--denim-spacing-24);
                --paragraph-top-margin: var(--denim-spacing-16);
            }
        }

        & .chart {
            border: var(--denim-spacing-2) solid var(--denim-color-shade-white-alpha-20);
            border-radius: var(--denim-border-radius);
            padding: var(--denim-spacing-24) var(--denim-spacing-24) var(--denim-spacing-16);
        }

        .more-info {
            & > .title {
                padding: 0;
            }

            & p:first-child {
                margin-bottom: var(--denim-spacing-8);
            }
        }
        /* stylelint-enable no-descending-specificity */
    }

    & .faq {
        & :--heading {
            --heading-margin: 0 0 var(--denim-spacing-16);
        }

        & .more-info {
            --more-info-margin: 0;

            & + .more-info {
                --more-info-margin: var(--denim-spacing-8) 0 0;
            }
        }

        /* stylelint-disable no-descending-specificity */
        & .button {
            --button-margin: var(--denim-spacing-16) 0 0;
        }
        /* stylelint-enable no-descending-specificity */
    }

    & .sign-up-bottom {
        display: grid;
        gap: var(--denim-spacing-24);
        grid-template:
            "feedback" auto
            "button" auto
            "reference" auto
            / 1fr;
        justify-items: start;

        & > .feedback {
            --heading-margin: 0;

            grid-area: feedback;
        }

        & > .reference {
            color: var(--denim-color-shade-white-alpha-60);
            grid-area: reference;
        }

        /* stylelint-disable no-descending-specificity */
        & > .button {
            grid-area: button;
        }
        /* stylelint-enable no-descending-specificity */
    }

    @media (--denim-breakpoint-above-mobile) {
        & {
            & .six-five-grid-container {
                & :--list {
                    --list-bottom-margin: var(--denim-spacing-32);
                    --list-top-margin: var(--denim-spacing-32);
                }
            }

            & .hero {
                --grid-row-gap: 0;

                & .hero-content {
                    align-self: center;
                }

                & .animated-image {
                    --hero-graphic-width: calc(50vw - var(--denim-spacing-48));
                }
            }

            & .bullets {
                & .content {
                    --bullets-flex-direction: row;

                    justify-content: space-between;
                }

                & .point {
                    width: 33%;

                    &.second {
                        justify-content: center;
                    }

                    &.third {
                        justify-content: flex-end;
                    }

                    & + .point {
                        margin: 0;
                    }
                }
            }

            & .sign-up-middle {
                --sign-up-middle-align-items: center;
                --sign-up-middle-flex-direction: row;

                justify-content: space-between;

                & :--heading {
                    --heading-margin: 0;
                }
            }

            & .sign-up-bottom {
                grid-template:
                    "feedback button" auto
                    "reference button" auto
                    / 4fr 2fr;

                & > .button {
                    place-self: center end;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .page-content {
                &.page-title {
                    --page-content-padding: 0 0 var(--denim-spacing-48);
                }
            }

            & .hero {
                grid-template-areas: "content content content image image image";
            }

            & .secure {
                --secure-padding: var(--denim-spacing-48);
            }
        }
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .page-content {
                &.page-title {
                    --page-content-padding: 0 0 var(--denim-spacing-56);
                }
            }

            & .secure {
                --secure-padding: var(--denim-spacing-56);
            }

            & .about-us {
                & .people {
                    --people-flex-direction: row;

                    justify-content: space-evenly;
                }

                & .person {
                    --person-align-items: flex-start;
                    --person-flex-direction: column;
                    --person-img-dimenssion: 168px;
                    --person-img-margin: 0 0 var(--denim-spacing-16);

                    width: 33%;

                    &.one {
                        margin: var(--denim-spacing-64) 0 0;
                    }

                    &.two {
                        margin: var(--denim-spacing-32) 0 0;
                    }

                    &.three {
                        align-self: flex-start;
                    }

                    & + .person {
                        --person-img-margin: 0 0 var(--denim-spacing-16);
                    }
                }
            }

            & .sign-up-bottom {
                grid-template:
                    "feedback button" auto
                    "reference button" auto
                    / 1fr 1fr;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            & .hero {
                grid-template-areas: "content content content content . image image image image image";
            }

            & .about-us {
                /* The images odf the scientists overlap on this breakpoint only, so twerk the grid slightly */
                grid-template:
                    "six six six six six six six . five five five five" auto
                    / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .saliva-test {
                & .button {
                    --button-margin: 0 0 var(--denim-spacing-32);
                }
            }

            & .hero {
                grid-template-areas: "content content content content content . image image image image image image";

                & .hero-content {
                    padding: 80px 0 0;

                    & h1 {
                        --heading-margin: 0 0 var(--denim-spacing-24);
                    }

                    & p {
                        --paragraph-bottom-margin: var(--denim-spacing-32);
                    }

                    & .button {
                        --button-margin: 0 0 var(--denim-spacing-32);
                    }
                }

                & .animated-image {
                    --hero-graphic-width: 100%;
                }
            }

            & .bullets {
                & img {
                    margin: 1px var(--denim-spacing-8) 0 0;
                }
            }
        }
    }
}

.genomics-group {
    --page-content-padding: var(--denim-spacing-16) 0;

    color: var(--denim-color-shade-white-alpha-60);

    & .two-thirds-container {
        --two-thirds-container-one-third-margin: 0;
    }
}

.trust-and-privacy {
    & .offset-title {
        --heading-margin: 0;

        & + .two-thirds-container {
            margin: var(--denim-spacing-16) 0 0;
        }
    }

    /* stylelint-disable no-descending-specificity */
    & :--list {
        margin: 0;

        & > li {
            margin: 0;

            & + li {
                margin: var(--denim-spacing-16) 0 0;
            }
        }

        & + p {
            /* Taking into account the extra 8px bottom margin on the list, otherwise this should be 24px */
            --paragraph-top-margin: var(--denim-spacing-16) !important; /* stylelint-disable-line declaration-no-important */
        }
    }
    /* stylelint-enable no-descending-specificity */

    & .supplemental {
        --card-margin: var(--denim-spacing-24) 0 0;
        --card-padding: var(--denim-spacing-16);

        background: var(--ihr-mid-navy-background);
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .offset-title {
                & + .two-thirds-container {
                    margin: var(--denim-spacing-24) 0 0;
                }
            }

            & :--list {
                margin: var(--denim-spacing-8) 0;

                & > li {
                    & + li {
                        margin: var(--denim-spacing-24) 0 0;
                    }
                }

                & + p {
                    /* Taking into account the extra 8px bottom margin on the list, otherwise this should be 32px */
                    --paragraph-top-margin: var(--denim-spacing-24) !important;  /* stylelint-disable-line declaration-no-important */
                }
            }

            & .supplemental {
                --card-margin: var(--denim-spacing-32) 0 0;
                --card-padding: var(--denim-spacing-24) var(--denim-spacing-32);
            }
        }
    }
}

.check {
    background-color: var(--denim-color-shade-white-alpha-20);
    border-radius: 50%;
    margin-right: var(--denim-icon-before-margin, var(--denim-spacing-8));
    padding: 2px 3px 3px 2px;

    &.icon {
        --icon-background-color: var(--genomics-color-light-green);
        --icon-size: var(--denim-spacing-16);

        &.before {
            &::before {
                --icon-before-margin: 0;
            }
        }
    }
}
