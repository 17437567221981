:root {
    /* The current Genomics Plc branding colour scheme                                                                */
    /* These should /only/ be used by the custom properties within this file and mapped to other custom properties    */
    /* that are used elsewhere.                                                                                       */
    /* ************************************************************************************************************** */

    /* Genomics branding colours… */
    --genomics-color-navy: rgb(17 0 53);
    --genomics-color-navy-alpha-80: rgb(17 0 53 / 80%);
    --genomics-color-navy-alpha-60: rgb(17 0 53 / 60%);
    --genomics-color-navy-alpha-50: rgb(17 0 53 / 50%);
    --genomics-color-navy-alpha-40: rgb(17 0 53 / 40%);
    --genomics-color-navy-alpha-20: rgb(17 0 53 / 20%);
    --genomics-color-navy-alpha-0: rgb(17 0 53 / 0%);
    --genomics-color-light-grey: rgb(239 239 239);
    --genomics-color-light-grey-alpha-50: rgb(239 239 239 / 50%);
    --genomics-color-light-green: rgb(51 255 196);
    --genomics-color-light-green-alpha-50: rgb(51 255 196 / 50%);
    --genomics-color-light-green-alpha-20: rgb(51 255 196 / 20%);
    --genomics-color-light-green-alpha-0: rgb(51 255 196 / 0%);
    --genomics-color-bright-blue: rgb(13 94 255);
    --genomics-color-bright-blue-alpha-50: rgb(13 94 255 / 50%);
    --genomics-color-bright-blue-alpha-40: rgb(13 94 255 / 40%);
    --genomics-color-purple: rgb(124 37 223);
    --genomics-color-purple-alpha-50: rgb(124 37 223 / 50%);
    --genomics-color-pink: rgb(252 64 130);
    --genomics-color-pink-alpha-50: rgb(252 65 131 / 50%);
    --genomics-color-yellow: rgb(255 216 0);
    --genomics-color-yellow-alpha-50: rgb(255 216 0 / 50%);
    --genomics-color-orange: rgb(255 83 1);
    --genomics-color-orange-alpha-50: rgb(255 83 1 / 50%);

    /* Custom properties that are defaulted in the Denim stylesheet, but which we want to use in IHR,                 */
    /* so they need to be defined here...                                                                             */
    /* ************************************************************************************************************** */

    /* Values for consistent spacing values… */
    --denim-spacing-2: 2px;
    --denim-spacing-4: 4px;
    --denim-spacing-8: 8px;
    --denim-spacing-12: 12px;
    --denim-spacing-16: 16px;
    --denim-spacing-24: 24px;
    --denim-spacing-32: 32px;
    --denim-spacing-40: 40px;
    --denim-spacing-48: 48px;
    --denim-spacing-56: 56px;
    --denim-spacing-64: 64px;
    --denim-spacing-72: 72px;
    --denim-spacing-88: 88px;
    --denim-spacing-96: 96px;

    /* Other colours that aren't necessarily in the Genomics brand guidelines, but are complimentary to them. */
    --denim-color-mid-navy: rgb(35 0 112);
    --denim-color-light-navy: rgb(50 0 158);

    /* Shades of grey… */
    --denim-color-shade-white: rgb(255 255 255);
    --denim-color-shade-white-alpha-80: rgb(255 255 255 / 80%);
    --denim-color-shade-white-alpha-60: rgb(255 255 255 / 60%);
    --denim-color-shade-white-alpha-50: rgb(255 255 255 / 50%);
    --denim-color-shade-white-alpha-40: rgb(255 255 255 / 40%);
    --denim-color-shade-white-alpha-20: rgb(255 255 255 / 20%);
    --denim-color-shade-off-white: rgb(249 249 249);
    --denim-color-shade-off-white-alpha-50: rgb(249 249 249 / 50%);
    --denim-color-shade-light-grey: rgb(239 239 239);
    --denim-color-shade-light-grey-alpha-50: rgb(239 239 239 / 50%);
    --denim-color-shade-mid-grey: rgb(189 189 189);
    --denim-color-shade-mid-grey-alpha-50: rgb(189 189 189 / 50%);
    --denim-color-shade-dark-grey: rgb(100 100 100);
    --denim-color-shade-dark-grey-alpha-50: rgb(100 100 100 / 50%);
    --denim-color-shade-black: rgb(29 29 29);
    --denim-color-shade-black-alpha-50: rgb(29 29 29 / 50%);

    /* Errors… */
    --denim-color-error-on-dark: rgb(252 64 130);
    --denim-color-error-on-dark-alpha-50: rgb(252 64 130 / 50%);
    --denim-color-error-on-dark-alpha-10: rgb(252 64 130 / 10%);
    --denim-color-error-on-light: rgb(224 34 101);
    --denim-color-error-on-light-alpha-50: rgb(224 34 101 / 50%);
    --denim-color-error-on-light-alpha-10: rgb(224 34 101 / 10%);

    /* Base background and text colors… */
    --denim-background-color: var(--genomics-color-navy, rgb(17 0 53));
    --denim-color: var(--denim-color-shade-off-white, rgb(249 249 249));
    --denim-color-dark: var(--denim-color-shade-mid-grey, rgb(189 189 189));
    --denim-color-really-dark: var(--denim-color-shade-dark-grey, rgb(100 100 100));

    /* Gradients… */
    --denim-gradient-blue-light-green: linear-gradient(90deg, var(--genomics-color-bright-blue, rgb(13 94 255)) 0%, var(--genomics-color-light-green, rgb(51 255 196)) 100%);
    --denim-gradient-orange-pink: linear-gradient(225deg, var(--genomics-color-orange, rgb(255 83 1)) 30.68%, var(--genomics-color-pink, rgb(252 64 130)) 100%);
    --denim-gradient-purple-blue: linear-gradient(55.54deg, var(--genomics-color-purple, rgb(124 37 223)) -67.53%, var(--genomics-color-bright-blue, rgb(13 94 255)) 130.52%);

    /* Grid width, which his also used by things that don't use the main grid, but need to look like they do… */
    --denim-grid-max-width: 1072px;

    /* Border radius… */
    --denim-border-radius: var(--denim-spacing-8);

    /* Default font size… */
    --denim-font-size: 16px;

    /* Default icon sizes… */
    --denim-icon-margin: calc(var(--denim-font-size, 16px) / 2);       /*  8px */
    --denim-icon-size: calc(var(--denim-font-size, 16px) * 1.5);       /* 24px */
    --denim-icon-size-large: calc(var(--denim-font-size, 16px) * 2);   /* 32px */

    /* Link colours… */
    --denim-link-color-on-dark: var(--denim-color);
    --denim-link-color-on-light: var(--genomics-color-bright-blue);

    /* Modal overlay z-index… */
    --denim-modal-overlay-z-index: 3;

    /* Common custom properties that are solely used within IHR…                                                      */
    /* ************************************************************************************************************** */

    --ihr-navbar-height: 74px;
    --ihr-margin-mobile: var(--denim-spacing-16);
    --ihr-margin-tablet-portrait: var(--denim-spacing-32);
    --ihr-margin-tablet-landscape: var(--denim-spacing-48);
    --ihr-margin-desktop: var(--denim-spacing-64);

    /* Modal dialog background colour… */
    --modal-dialog-overlay-color: var(--genomics-color-navy-alpha-80);
    --modal-dialog-background-color: var(--denim-color-light-navy);
    --modal-dialog-background-mix-blend-mode: normal;

    /* Mid-navy gradient background… */
    --ihr-mid-navy-background: var(--denim-color-mid-navy) linear-gradient(180deg, var(--genomics-color-light-green-alpha-0) 0%, var(--genomics-color-light-green-alpha-20) 100%);

    /* Active menu items… */
    --ihr-active-link: var(--denim-active-menu-item, 2px solid var(--genomics-color-light-green, rgb(51 255 196)));
}
