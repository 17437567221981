.action-card {
    --card-padding: 0 !important; /* stylelint-disable-line declaration-no-important */

    &:not(:first-of-type) {
        margin-top: 32px;
    }

    & > .header {
        --action-card-background-image: var(--denim-gradient-blue-light-green);
        --action-card-background-size: auto 8px;
        --action-card-header-color: inherit;
        --action-card-header-padding: 32px 16px 0 16px;

        background-image: var(--action-card-background-image);
        background-repeat: no-repeat;
        background-size: var(--action-card-background-size);
        border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
        color: var(--action-card-header-color);
        padding: var(--action-card-header-padding);

        & .step {
            --action-card-step-color: var(--genomics-color-navy-alpha-60);
            --action-card-step-margin: 0 0 8px;

            color: var(--action-card-step-color);
            margin: var(--action-card-step-margin);
        }

        &.graphical-background {
            --action-card-background-size: cover;
            --action-card-header-padding: 32px 16px 24px 16px;
            --action-card-header-color: var(--denim-color-shade-white);
            --graphical-background-linear-gradient: linear-gradient(360deg, var(--genomics-color-navy) 0%, var(--genomics-color-navy-alpha-50) 100%);

            background-position: center;

            & .step {
                --action-card-step-color: var(--denim-color-shade-white-alpha-80);
            }

            &.alcohol {
                --action-card-background-image: var(--graphical-background-linear-gradient), url("images/lifestyle-alcohol.jpg");
            }

            &.diet {
                --action-card-background-image: var(--graphical-background-linear-gradient), url("images/lifestyle-diet.jpg");
            }

            &.exercise {
                --action-card-background-image: var(--graphical-background-linear-gradient), url("images/lifestyle-exercise.jpg");
            }

            &.sleep {
                --action-card-background-image: var(--graphical-background-linear-gradient), url("images/lifestyle-sleep.jpg");
            }

            &.smoke {
                --action-card-background-image: var(--graphical-background-linear-gradient), url("images/lifestyle-smoke.jpg");
            }

            &.weight {
                --action-card-background-image: var(--graphical-background-linear-gradient), url("images/lifestyle-weight.jpg");
            }
        }
    }

    & > .body {
        --action-card-body-padding: 24px 16px 32px 16px;

        padding: var(--action-card-body-padding);

        & :first-child {
            margin-top: 0;
        }

        & p {
            --paragraph-bottom-margin: 16px;
            --paragraph-top-margin: 24px;
        }

        & .heading {
            --paragraph-bottom-margin: 0;
            --paragraph-top-margin: 24px;

            & + p {
                --paragraph-top-margin: 0;
            }
        }

        & .is-caption {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }

        & ul {
            --list-bottom-margin: 16px;
            --list-top-margin: 0;

            & li {
                --list-item-bottom-margin: 0;
                --list-item-top-margin: 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & > .header {
                --action-card-header-padding: 40px 24px 0 24px;

                &.graphical-background {
                    --action-card-header-padding: 40px 24px 24px 24px;
                }
            }

            & > .body {
                --action-card-body-padding: 24px 24px 32px 24px;
            }
        }
    }
}
