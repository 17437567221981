.text-input-width-container {
    margin: 0;

    & > * {
        grid-column: 1 / 5;
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            display: grid;
            grid-column-gap: var(--denim-grid-column-gap, 32px);
            grid-template-columns: repeat(5, minmax(0, 1fr));
            grid-template-rows: 1fr;
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            display: grid;
            grid-column-gap: var(--denim-grid-column-gap, 32px);
            grid-template-columns: repeat(7, minmax(0, 1fr));
            grid-template-rows: 1fr;
        }
    }
}

.sign-up-invite-code {
    & .text-input {
        --text-input-width: 185px;
    }
}

/* Attempt to centre the loading component */
.sign-up-account-setup {
    & .linear-flow {
        min-height: calc(100vh / 2);

        & .content {
            align-self: end;
        }
    }
}

.sign-up-verify-email {
    & .more-info {
        & .button {
            --button-margin: 0 0 16px;
        }
    }
}

.sign-up-shipping-details {
    & .form-group {
        --form-group-description-color: var(--denim-color-shade-white-alpha-60);
    }
}

.sign-up-summary,
.test-reorder-check-your-details {
    & .question-and-answer {
        &:not(:last-of-type) {
            margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}
