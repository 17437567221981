.divider {
    --divider-color: var(--ihr-divider-color-on-dark, rgb(255 255 255));
    --divider-width: var(--ihr-divider-width, var(--denim-spacing-24, 24px));
    --divider-margin: var(--ihr-divider-margin, var(--denim-spacing-32, 32px) 0);

    border-color: var(--divider-color);
    margin: var(--divider-margin);
    width: var(--divider-width);

    .variant-light & {
        --divider-color: var(--ihr-divider-color-on-light, rgb(17 0 53));
    }
}

.test-block {
    & .is-caption {
        color: var(--denim-color-shade-white-alpha-60);
    }

    & + & {
        margin-top: var(--denim-spacing-48);
    }
}

.test {
    --card-border-radius: 0;
    --card-padding: var(--denim-spacing-24) var(--denim-spacing-24) var(--denim-spacing-24) 0;
    --card-margin: 0;

    align-items: center;
    display: flex;
    justify-content: flex-start;

    & .card-icon {
        --card-icon-margin: 0 var(--denim-spacing-12) 0 0;
        --card-icon-size: 26px;

        margin: var(--card-icon-margin);
        width: var(--card-icon-size);
    }

    & .test-name {
        --heading-margin: 0 0 var(--denim-spacing-8);
    }

    &.included {
        & .test-name {
            --heading-margin: 0;
        }
    }

    &.not-included {
        & .card-icon {
            align-self: flex-start;
        }
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--denim-color-shade-white-alpha-20);
    }

    @media (--denim-breakpoint-above-tablet-portrait) {
        & {
            & .card-icon {
                --card-icon-margin: 0 var(--denim-spacing-16) 0 0;
            }
        }
    }
}

.summary-section {
    &:not(:first-of-type) {
        margin-top: var(--denim-spacing-48);
    }

    & .is-eyebrow {
        border-bottom: 1px solid var(--denim-color-shade-white-alpha-20);
        color: var(--denim-color-shade-white-alpha-60);
        padding: 0 0 var(--denim-spacing-8);
    }
}

.question-and-answer {
    display: grid;
    grid:
        "question" auto
        "answer" auto
        / auto;
    padding: var(--denim-spacing-24) 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--denim-color-shade-white-alpha-20);
    }

    &.is-editable {
        grid:
            "question edit" auto
            "answer ." auto
            / 1fr auto;
    }

    & .question {
        color: var(--denim-color-shade-white-alpha-60);
        grid-area: question;
        margin-bottom: var(--denim-spacing-4);
    }

    & .edit {
        grid-area: edit;
    }

    & .answer {
        grid-area: answer;

        & ul {
            --list-bottom-margin: 0;
            --list-top-margin: 0;

            & li {
                --list-item-bottom-margin: 0;
                --list-item-top-margin: 0;
            }
        }
    }
}

.address-whitespace {
    white-space: pre-line;
}
